export const roles = [
  { name: "Seller", id: "1", type: "seller" },
  { name: "Buyer", id: "2", type: "buyer" },
];

export const statusOption = [
  { label: "Verified", value: "verified" },
  { label: "Unverfied", value: "unverfied" },
];

export const category = {
  "Crop Nutritions": [
    "Amino Acids",
    "Gibberellic Acids",
    "Micronutrients",
    "PGPR's (Plant Growth-Promoting Rhizobacteria)",
    "Plant Growth Regulaters",
  ],
  Fertilizers: [
    "Anti Stress Fertilizers",
    "Basic Fertilizers",
    "Bio Fertilizers (Organic Fertilizers)",
    "Growth Stimulaters",
    "Imported Fertilizers",
    "Liquid Fertilizers",
    "Organic Fertilizers",
    "Soil Conditioners",
  ],
  Pesticides: [
    "Adjuvants",
    "Fumigants",
    "Fungicides",
    "Fungicides And Bactericides",
    "Granules Insecticides (Poisions)",
    "Herbicides / Weedicides",
    "Insecticides",
    "Seed Treatments",
  ],
};

export const packagingType = [
  { id: 1, label: "Plastic Bottle", value: "plasticbottle" },
  { id: 2, label: "Plastic Bags", value: "plasticbag" },
  { id: 3, label: "Aluminium Bottle", value: "aluminiumbottle" },
  { id: 4, label: "Cloth Bags", value: "clothbag" },
  { id: 5, label: "Burlap Bags", value: "burlapbags" },
  { id: 6, label: "Boxes / Crates", value: "boxescrates" },
];

export const weightUnitType = [
  { id: 1, label: "Gram", value: "gram" },
  { id: 2, label: "Milligram ", value: "milligram " },
  { id: 3, label: "Kg", value: "kg" },
  { id: 4, label: "Liter", value: "liter" },
  { id: 5, label: "Milliliter ", value: "milliliter " },
  { id: 8, label: "Percentage  ", value: "percentage" },
];

export const seedTypeOption = [
  { id: 1, label: "Hybrid", value: "hybrid" },
  { id: 2, label: "Synthetic", value: "synthetic" },
  { id: 2, label: "Organic", value: "organic" },
];

export const seedRegionOption = [
  { id: 1, label: "Irrigated", value: "irrigated" },
  { id: 2, label: "Rainfed", value: "rainfed" },
  { id: 2, label: "Drought", value: "drought" },
];

export const machinaryToolsOption = [
  { id: 1, label: "Tool", value: "Tool" },
  { id: 2, label: "Machinary", value: "Machinary" },
];

export const toolCondition = [
  { id: 1, label: "New", value: "new" },
  { id: 2, label: "Used", value: "used" },
];

export const brands = [
  { id: 1, label: "agri innotech", value: "agri innotech" },
  { id: 2, label: "agritech", value: "agritech" },
  { id: 3, label: "agpharma", value: "agpharma" },
  { id: 4, label: "agro guard", value: "agro guard" },
  { id: 5, label: "agromart group", value: "agromart group" },
  { id: 6, label: "alnoor agro chemicals", value: "alnoor agro chemicals" },
  { id: 7, label: "arysta", value: "arysta" },
  { id: 8, label: "barkat fertilizers", value: "barkat fertilizers" },
  { id: 9, label: "bayer crop sciences", value: "bayer crop sciences" },
  {
    id: 10,
    label: "bio ag service pakistan",
    value: "bio ag service pakistan",
  },
  { id: 11, label: "byter crop protection", value: "byter crop protection" },
  { id: 12, label: "dap", value: "dap" },
  { id: 13, label: "djc", value: "djc" },
  { id: 14, label: "engro fertilizer ltd", value: "engro fertilizer ltd" },
  { id: 15, label: "evyol group", value: "evyol group" },
  { id: 16, label: "farm evo", value: "farm evo" },
  {
    id: 17,
    label: "fatima fertilizer company ltd",
    value: "fatima fertilizer company ltd",
  },
  { id: 18, label: "fertiscience", value: "fertiscience" },
  {
    id: 19,
    label: "ffc - fauji fertilizer company",
    value: "ffc - fauji fertilizer company",
  },
  { id: 20, label: "fmc corporation", value: "fmc corporation" },
  { id: 21, label: "four brothers", value: "four brothers" },
  { id: 22, label: "global products", value: "global products" },
  { id: 23, label: "gr agro service", value: "gr agro service" },
  { id: 24, label: "green circle", value: "green circle" },
  {
    id: 25,
    label: "green pakistan nature farming",
    value: "green pakistan nature farming",
  },
  { id: 26, label: "green crop", value: "green crop" },
  { id: 27, label: "greenlet international", value: "greenlet international" },
  { id: 28, label: "grow plant pvt. ltd", value: "grow plant pvt. ltd" },
  { id: 29, label: "hara organic pakistan", value: "hara organic pakistan" },
  { id: 30, label: "higgro", value: "higgro" },
  { id: 31, label: "hmc", value: "hmc" },
  { id: 32, label: "ici pakistan", value: "ici pakistan" },
  { id: 33, label: "ics tara group", value: "ics tara group" },
  {
    id: 34,
    label: "jaffer brothers agro services pvt. ltd",
    value: "jaffer brothers agro services pvt. ltd",
  },
  { id: 35, label: "jalunder", value: "jalunder" },
  { id: 36, label: "kanzo", value: "kanzo" },
  { id: 37, label: "kissan agro", value: "kissan agro" },
  { id: 38, label: "manuchar agro", value: "manuchar agro" },
  { id: 39, label: "matra asia pvt. ltd", value: "matra asia pvt. ltd" },
  { id: 40, label: "maxim agri pvt. ltd", value: "maxim agri pvt. ltd" },
  { id: 41, label: "mynita plus", value: "mynita plus" },
  { id: 42, label: "nuchem", value: "nuchem" },
  { id: 43, label: "orgevit", value: "orgevit" },
  { id: 43, label: "samsayal", value: "samsayal" },
  { id: 44, label: "perfect chemical", value: "perfect chemical" },
  { id: 45, label: "plant for life", value: "plant for life" },
  { id: 46, label: "range", value: "range" },
  { id: 47, label: "rudolf group", value: "rudolf group" },
  { id: 48, label: "sarsabz", value: "sarsabz" },
  { id: 49, label: "saver international", value: "saver international" },
  {
    id: 50,
    label: "shifang anda chemical co",
    value: "shifang anda chemical co",
  },
  { id: 51, label: "sinopak", value: "sinopak" },
  {
    id: 52,
    label: "solex chemical pvt. ltd",
    value: "solex chemical pvt. ltd",
  },
  { id: 53, label: "sun crop", value: "sun crop" },
  {
    id: 54,
    label: "suraj fertilizer industries pvt. ltd",
    value: "suraj fertilizer industries pvt. ltd",
  },
  { id: 55, label: "swat agro chemicals", value: "swat agro chemicals" },
  { id: 56, label: "syngenta", value: "syngenta" },
  { id: 57, label: "tara group", value: "tara group" },
  { id: 58, label: "target", value: "target" },
  { id: 59, label: "top sun", value: "top sun" },
  {
    id: 60,
    label: "united agro fertilizers (7 star fertilizer)",
    value: "united agro fertilizers (7 star fertilizer)",
  },
  { id: 61, label: "vcs", value: "vcs" },
  { id: 62, label: "ventage", value: "ventage" },
  { id: 63, label: "vision crop sciences", value: "vision crop sciences" },
  { id: 64, label: "welcon", value: "welcon" },
  { id: 65, label: "wuxal (brand of BASF)", value: "wuxal (brand of BASF)" },
  {
    id: 66,
    label: "zhengband agriculture pakistan pvt. ltd",
    value: "zhengband agriculture pakistan pvt. ltd",
  },
  { id: 67, label: "zinkron", value: "zinkron" },
  { id: 68, label: "kissan ghar", value: "kissanghar" },
].sort((a, b) => a.label.localeCompare(b.label));
